import React from 'react'
import Helmet from 'react-helmet'

import Gallery from '../components/Gallery'
import Layout from '../components/layout'
import Party from '../assets/images/cover/paintParties.jpg'
import Logo from '../assets/images/logo.png'

const HomeIndex = () => {
  const siteTitle = 'Reagan Deming Art'
  const siteDescription = 'Reagan Deming Custom Fine Art'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="Reagan Deming Art" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="two">
          <img
            className="desktopLogo"
            src={Logo}
            alt="Logo reading Reagan Deming Custom Fine Art"
          />
        </section>
        <section id="two">
          <h1 id="more">Gallery</h1>

          <Gallery />

          <ul className="actions">
            <li>
              <a href="/404" className="button">
                Full Gallery
              </a>
            </li>
          </ul>
        </section>

        <section id="two">
          <h2>Paint Parties!</h2>
          <img
            src={Party}
            alt="Artist Reagan Deming teaching a painting class"
          />

          <p>
            Create a customized painting of your choice with friends! Great for
            birthday parties, office parties and other celebrations. Who needs
            an excuse to paint?
          </p>
        </section>

        <section id="one">
          <header className="major">
            <h2>About the Artist</h2>
          </header>
          <p>
            I am Reagan Deming, a North Texas-based artist and muralist working
            out of my home studio, your home or business, or basically, anywhere
            you need. I create the mural you want, where you want it, and am
            happy to express my own artistic voice or help you find yours. I
            also offer custom art, reproductions, the ever-popular pet portrait
            to immortalize a Fluffy or Fido near you, and paint parties. Safe
            for all ages, my paint parties include a customized painting of your
            choice, all art supplies, set up and takedown, sound system needs,
            and my quirky personality. I guide you and your guests through
            simple, easy-to-follow, and unrushed steps, so you feel like
            accomplished artists with a memorable experience and a keepsake to
            take home. I am willing to travel to you or help you find a space
            that fits your needs.
          </p>
          <ul className="actions">
            <li>
              <a href="/about" className="button">
                Learn More
              </a>
            </li>
          </ul>
        </section>

        <section id="three">
          <h2 className="contact">Let's Get Creative</h2>

          <div className="row">
            <div className="8u 12u$(small)">
              <form
                name="Contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label>
                    Don’t fill this out: <input name="bot-field" />
                  </label>
                </p>
                <div className="row uniform 50%">
                  <div className="6u 12u$(xsmall)">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                    />
                  </div>
                  <div className="6u 12u$(xsmall)">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="12u">
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Message"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
                <ul className="actions" style={{ marginTop: 30 }}>
                  <li>
                    <input type="submit" value="Send Message" />
                  </li>
                </ul>
              </form>
            </div>
            <div className="4u 12u$(small)">
              <ul className="labeled-icons">
                <li className="contact">
                  <h3 className="icon fa-mobile">
                    <span className="label">Phone</span>
                  </h3>
                  <a href="tel8179926444"> 817-992-6444</a>
                </li>
                <li className="contact">
                  <h3 className="icon fa-envelope-o">
                    <span className="label">Email</span>
                  </h3>
                  <a href="mailto: reagan@reagandemingart.com">
                    reagan@reagandemingart.com
                  </a>
                </li>
              </ul>
              <div className="inner">
                <ul className="icons">
                  <li>
                    <a
                      href="https://www.instagram.com/genuinereagan/?hl=en"
                      className="icon fa-instagram"
                    >
                      <span className="label">Instagram</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.facebook.com/reagandemingart/"
                      className="icon fa-facebook"
                    >
                      <span className="label">Facebook</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="two">
          <img
            className="mobileLogo"
            src={Logo}
            alt="Logo reading Reagan Deming Custom Fine Art"
          />
          <ul className="copyright">
            <li>&copy; Reagan Deming</li>
            <li>
              A <a href="https://www.swirlwebdesign.com/">Swirl Creative</a> Web
              Design Project
            </li>
          </ul>
        </section>
      </div>
    </Layout>
  )
}

export default HomeIndex
