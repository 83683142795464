import full01 from '../../../assets/images/cover/petPortraits.jpeg'
import full02 from '../../../assets/images/cover/acrylicPrints.jpg'
import full03 from '../../../assets/images/cover/murals.jpg'
import full04 from '../../../assets/images/cover/cups.jpg'

import thumb01 from '../../../assets/images/cover/petPortraits.jpeg'
import thumb02 from '../../../assets/images/cover/acrylicPrints.jpg'
import thumb03 from '../../../assets/images/cover/murals.jpg'
import thumb04 from '../../../assets/images/cover/cups.jpg'

export const DEFAULT_IMAGES = [
  {
    id: '1',
    source: full01,
    thumbnail: thumb01,
    caption: 'Personalized Portraits',
    description:
      'Immortalize your pet or family member with art that captures their essence',
  },
  {
    id: '2',
    source: full02,
    thumbnail: thumb02,
    caption: 'Prints',
    description:
      'Looking to procure cool art without breaking the bank? Check out these prints made from coffee, wine and other mediums',
  },
  {
    id: '3',
    source: full03,
    thumbnail: thumb03,
    caption: 'Murals',
    description:
      'Personalize your home or office with a mural that transforms the space',
  },
  {
    id: '4',
    source: full04,
    thumbnail: thumb04,
    caption: 'Custom Artwork',
    description:
      'Do you have a work of art in your head you want to see come alive? If you can dream it, I can create it',
  },
]
